import { $, Timer, moment, createElement } from '@gibme/web-ui';
import fetch from '@gibme/fetch';
import { GHTransformedResult } from './github';

const base_url = 'https://commits.applet.cc';

const buildCard = (commit: GHTransformedResult): JQuery<HTMLElement> => {
    const card = createElement('div')
        .addClass('card mb-2')
        .css('cursor', 'pointer')
        .on('click', () => {
            window.open(commit.url);
        });

    const body = createElement('div')
        .addClass('card-body');

    const row = createElement('div')
        .addClass('d-flex flex-row');

    {
        const cell = createElement('div');

        createElement('img')
            .addClass('rounded-circle shadow')
            .prop({
                width: 64,
                src: commit.committer.avatar_url
            })
            .appendTo(cell);

        cell.appendTo(row);
    }

    const cell = createElement('div')
        .addClass('ms-3 me-3');

    {
        const innerRow = createElement('div')
            .addClass('d-flex flex-row');

        createElement('div')
            .addClass('fw-bold')
            .html(commit.committer.name)
            .appendTo(innerRow);

        createElement('div')
            .addClass('ms-3 fst-italic')
            .text(`@${commit.committer.login}`)
            .appendTo(innerRow);

        createElement('div')
            .addClass('ms-3')
            .attr('data-timestamp', commit.committer.timestamp)
            .text(moment(commit.committer.timestamp).fromNow())
            .appendTo(innerRow);

        innerRow.appendTo(cell);
    }

    {
        const innerRow = createElement('div')
            .addClass('d-flex flex-row');

        createElement('div')
            .addClass('fst-italic')
            .text(`${commit.repo}#${commit.sha}`)
            .appendTo(innerRow);

        innerRow.appendTo(cell);
    }

    {
        const innerRow = createElement('div')
            .addClass('mt-2');

        createElement('div')
            .html(commit.message.replace(/(?:\r\n|\r|\n)/gi, '<br />'))
            .appendTo(innerRow);

        innerRow.appendTo(cell);
    }

    cell.appendTo(row);

    row.appendTo(body);

    body.appendTo(card);

    return card;
};

const fetch_commits = async (login: string, timeout = 5_000): Promise<GHTransformedResult[]> => {
    const response = await fetch.get(`${base_url}/commits/${login}`, {
        timeout
    });

    if (!response.ok) {
        throw new Error(`[${response.status}] ${response.url} ${response.statusText}`);
    }

    return response.json();
};

const fetchM_commits = async (logins: string[], timeout = 5_000): Promise<GHTransformedResult[]> => {
    try {
        const promises = [];

        for (const login of logins) {
            promises.push(fetch_commits(login, timeout));
        }

        const results: GHTransformedResult[] = [];

        const responses = await Promise.all(promises);

        responses.map(response => results.push(...response));

        return results.sort((a, b) =>
            a.committer.timestamp - b.committer.timestamp);
    } catch {
        return [];
    }
};

$(document).ready(async () => {
    const url = window.location.toString();
    const container = $('#container');
    let latest_commit = 0;

    const params = new URLSearchParams(url.split('?')[1]);

    const limit = parseInt(params.get('limit') || '20');

    const logins = (() => {
        const logins = params.get('logins') || params.get('login');

        if (!logins) {
            return undefined;
        }

        return logins.split(',')
            .map(elem => elem.trim());
    })();

    if (!logins) {
        return;
    }

    $(document).attr('title', `Commits from ${logins.join(', ')}`);

    const refresh_timer = new Timer(60_000, true);

    refresh_timer.on('tick', () => {
        container.find('[data-timestamp').each((i, elem) => {
            const commit = $(elem);

            const timestamp = parseInt(commit.attr('data-timestamp') || '0');

            commit.text(moment(timestamp).fromNow());
        });
    });

    const timer = new Timer(2 * 60 * 1000, false);

    const insert = async (commit: JQuery<HTMLElement>, ts: number): Promise<void> => {
        return new Promise(resolve => {
            if (ts > latest_commit) {
                commit.slideUp({
                    duration: 0,
                    complete: () => {
                        commit.prependTo(container).slideDown({
                            complete: () => {
                                latest_commit = ts;

                                return resolve();
                            }
                        });
                    }
                });
            } else {
                return resolve();
            }
        });
    };

    const remove = async (commit: JQuery<HTMLElement>): Promise<void> => {
        return new Promise(resolve => {
            commit.slideUp({
                duration: 200,
                complete: () => {
                    commit.remove();

                    return resolve();
                }
            });
        });
    };

    timer.on('tick', async () => {
        try {
            const commits = await fetchM_commits(logins);

            for (const commit of commits) {
                const card = buildCard(commit);

                await insert(card, commit.committer.timestamp);

                while (container.children().length > limit) {
                    await remove(container.children().last());
                }
            }
        } catch (error: any) {
            console.log(error.toString());
        }
    });

    timer.tick();
    timer.start();
});
